import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: '#111111',
  },
  mediaQueries: {
    // above
    small: 'only screen and (min-width: 400px)',
    large: 'only screen and (min-width: 960px)',
    // bellow
    belowSmall: 'only screen and (max-width: 399.98px)',
    belowLarge: 'only screen and (max-width: 959.98px)'
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
