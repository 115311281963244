import React from "react"
import GlobalStyle from "./src/theme/GlobalStyle"
import Theme from "./src/theme/Theme"

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyle />
    <Theme>
      {element}
    </Theme>
  </>
)